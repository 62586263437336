import * as React from "react";
import { useState, useEffect } from "react";
import { Container, Modal, Button } from "react-bootstrap";

import "../layouts/gallery.css";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

import img0 from "../images/Gallery/Altinsaray/0.jpeg";
import img1 from "../images/Gallery/Altinsaray/1.jpeg";
import img2 from "../images/Gallery/Altinsaray/2.jpeg";
import img3 from "../images/Gallery/Altinsaray/3.jpeg";
import img4 from "../images/Gallery/Altinsaray/4.jpeg";
import img5 from "../images/Gallery/Altinsaray/5.jpeg";
import img6 from "../images/Gallery/Altinsaray/6.jpeg";
import img7 from "../images/Gallery/Altinsaray/7.jpeg";
import img8 from "../images/Gallery/Altinsaray/8.jpeg";
import img9 from "../images/Gallery/Altinsaray/9.jpeg";
import img10 from "../images/Gallery/Altinsaray/10.jpeg";
import img11 from "../images/Gallery/Altinsaray/11.jpeg";
import img12 from "../images/Gallery/Altinsaray/12.jpeg";
import img13 from "../images/Gallery/Altinsaray/13.jpeg";
import img14 from "../images/Gallery/Altinsaray/14.jpeg";
import img15 from "../images/Gallery/Altinsaray/15.jpeg";
import img16 from "../images/Gallery/Altinsaray/16.jpeg";
import img17 from "../images/Gallery/Altinsaray/17.jpeg";
import img18 from "../images/Gallery/Altinsaray/18.jpeg";
import img19 from "../images/Gallery/Altinsaray/19.jpeg";
import img20 from "../images/Gallery/Altinsaray/20.jpeg";
import img21 from "../images/Gallery/Altinsaray/21.jpeg";
import img22 from "../images/Gallery/Altinsaray/22.jpeg";
import img23 from "../images/Gallery/Altinsaray/23.jpeg";
import img24 from "../images/Gallery/Altinsaray/24.jpeg";
import img25 from "../images/Gallery/Altinsaray/25.jpeg";
import img26 from "../images/Gallery/Altinsaray/26.jpeg";
import img27 from "../images/Gallery/Altinsaray/27.jpeg";
import img28 from "../images/Gallery/Altinsaray/28.jpeg";
import img29 from "../images/Gallery/Altinsaray/29.jpeg";
import img30 from "../images/Gallery/Altinsaray/30.jpeg";
import img31 from "../images/Gallery/Altinsaray/31.jpeg";
import img32 from "../images/Gallery/Altinsaray/32.jpeg";
import img33 from "../images/Gallery/Altinsaray/33.jpeg";
import img34 from "../images/Gallery/Altinsaray/34.jpeg";
import img35 from "../images/Gallery/Altinsaray/35.jpeg";
import img36 from "../images/Gallery/Altinsaray/36.jpeg";
import img37 from "../images/Gallery/Altinsaray/37.jpeg";
import img38 from "../images/Gallery/Altinsaray/38.jpeg";
import img39 from "../images/Gallery/Altinsaray/39.jpeg";
import img40 from "../images/Gallery/Altinsaray/40.jpeg";
import img41 from "../images/Gallery/Altinsaray/41.jpeg";
import img42 from "../images/Gallery/Altinsaray/42.jpeg";
import img43 from "../images/Gallery/Altinsaray/43.jpeg";
import img44 from "../images/Gallery/Altinsaray/44.jpeg";
import img45 from "../images/Gallery/Altinsaray/45.jpeg";
import img46 from "../images/Gallery/Altinsaray/46.jpeg";
import img47 from "../images/Gallery/Altinsaray/47.jpeg";
import img48 from "../images/Gallery/Altinsaray/48.jpeg";
import img49 from "../images/Gallery/Altinsaray/49.jpeg";
import img50 from "../images/Gallery/Altinsaray/50.jpeg";
import img51 from "../images/Gallery/Altinsaray/51.jpeg";
import img52 from "../images/Gallery/Altinsaray/52.jpeg";
import img53 from "../images/Gallery/Altinsaray/53.jpeg";
import img54 from "../images/Gallery/Altinsaray/54.jpeg";
import img55 from "../images/Gallery/Altinsaray/55.jpeg";
import img56 from "../images/Gallery/Altinsaray/56.jpeg";
import img57 from "../images/Gallery/Altinsaray/57.jpeg";
import img58 from "../images/Gallery/Altinsaray/58.jpeg";
import img59 from "../images/Gallery/Altinsaray/59.jpeg";
import img60 from "../images/Gallery/Altinsaray/60.jpeg";
import img61 from "../images/Gallery/Altinsaray/61.jpeg";
import img62 from "../images/Gallery/Altinsaray/62.jpeg";
import img63 from "../images/Gallery/Altinsaray/63.jpeg";
import img64 from "../images/Gallery/Altinsaray/64.jpeg";
import img65 from "../images/Gallery/Altinsaray/65.jpeg";
import img66 from "../images/Gallery/Altinsaray/66.jpeg";
import img67 from "../images/Gallery/Altinsaray/67.jpeg";
import img68 from "../images/Gallery/Altinsaray/68.jpeg";
import img69 from "../images/Gallery/Altinsaray/69.jpeg";
import img70 from "../images/Gallery/Altinsaray/70.jpeg";
import img71 from "../images/Gallery/Altinsaray/71.jpeg";
import img72 from "../images/Gallery/Altinsaray/72.jpeg";
import img73 from "../images/Gallery/Altinsaray/73.jpeg";
import img74 from "../images/Gallery/Altinsaray/74.jpeg";
import img75 from "../images/Gallery/Altinsaray/75.jpeg";
import img76 from "../images/Gallery/Altinsaray/76.jpeg";
import img77 from "../images/Gallery/Altinsaray/77.jpeg";
import img78 from "../images/Gallery/Altinsaray/78.jpeg";
import img79 from "../images/Gallery/Altinsaray/79.jpeg";

const lang = 'de'

function Gallery() {
  const [ userLanguage, setUserLanguage ] = useState (lang);

  useEffect(() => {
    localStorage.getItem('lang') ? setUserLanguage(localStorage.getItem('lang')) : setUserLanguage(lang);
  }, [])

  let imageData = [
    { id: 0, imgSrc: img0},
    { id: 1, imgSrc: img1},
    { id: 2, imgSrc: img2},
    { id: 3, imgSrc: img3},
    { id: 4, imgSrc: img4},
    { id: 5, imgSrc: img5},
    { id: 6, imgSrc: img6},
    { id: 7, imgSrc: img7},
    { id: 8, imgSrc: img8},
    { id: 9, imgSrc: img9},
    { id: 10, imgSrc: img10},
    { id: 11, imgSrc: img11},
    { id: 12, imgSrc: img12},
    { id: 13, imgSrc: img13},
    { id: 14, imgSrc: img14},
    { id: 15, imgSrc: img15},
    { id: 16, imgSrc: img16},
    { id: 17, imgSrc: img17},
    { id: 18, imgSrc: img18},
    { id: 19, imgSrc: img19},
    { id: 20, imgSrc: img20},
    { id: 21, imgSrc: img21},
    { id: 22, imgSrc: img22},
    { id: 23, imgSrc: img23},
    { id: 24, imgSrc: img24},
    { id: 25, imgSrc: img25},
    { id: 26, imgSrc: img26},
    { id: 27, imgSrc: img27},
    { id: 28, imgSrc: img28},
    { id: 29, imgSrc: img29},
    { id: 30, imgSrc: img30},
    { id: 31, imgSrc: img31},
    { id: 32, imgSrc: img32},
    { id: 33, imgSrc: img33},
    { id: 34, imgSrc: img34},
    { id: 35, imgSrc: img35},
    { id: 36, imgSrc: img36},
    { id: 37, imgSrc: img37},
    { id: 38, imgSrc: img38},
    { id: 39, imgSrc: img39},
    { id: 40, imgSrc: img40},
    { id: 41, imgSrc: img41},
    { id: 42, imgSrc: img42},
    { id: 43, imgSrc: img43},
    { id: 44, imgSrc: img44},
    { id: 45, imgSrc: img45},
    { id: 46, imgSrc: img46},
    { id: 47, imgSrc: img47},
    { id: 48, imgSrc: img48},
    { id: 49, imgSrc: img49},
    { id: 50, imgSrc: img50},
    { id: 51, imgSrc: img51},
    { id: 52, imgSrc: img52},
    { id: 53, imgSrc: img53},
    { id: 54, imgSrc: img54},
    { id: 55, imgSrc: img55},
    { id: 56, imgSrc: img56},
    { id: 57, imgSrc: img57},
    { id: 58, imgSrc: img58},
    { id: 59, imgSrc: img59},
    { id: 60, imgSrc: img60},
    { id: 61, imgSrc: img61},
    { id: 62, imgSrc: img62},
    { id: 63, imgSrc: img63},
    { id: 64, imgSrc: img64},
    { id: 65, imgSrc: img65},
    { id: 66, imgSrc: img66},
    { id: 67, imgSrc: img67},
    { id: 68, imgSrc: img68},
    { id: 69, imgSrc: img69},
    { id: 70, imgSrc: img70},
    { id: 71, imgSrc: img71},
    { id: 72, imgSrc: img72},
    { id: 73, imgSrc: img73},
    { id: 74, imgSrc: img74},
    { id: 75, imgSrc: img75},
    { id: 76, imgSrc: img76},
    { id: 77, imgSrc: img77},
    { id: 78, imgSrc: img78},
    { id: 79, imgSrc: img79}
  ];
  const [modalView, setModalView] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState("");

  const handleClose = () => setModalView(false);

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModalView(true);
  };

  return (
    <Container
    fluid
    style={{
      marginBottom: "0",
      maxWidth: "100%",
      paddingRight: "0",
      paddingLeft: "0",
    }}>
    {/* <> */}
    <NavBar  setLanguage={setUserLanguage} userLanguage={userLanguage} />

      <Modal
        show={modalView}
        onHide={handleClose}
        fullscreen={true}
        className="modal-style"
      >
        <Modal.Header closeButton closeVariant="white">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Header>
        <Modal.Body className="modal-body-style">
          <img src={tempImgSrc} className="modal-image" />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <div className="gallery-heading">
        <h2>Altinsaray</h2>
      </div>
      <div className="gallery">
        {imageData.map((item, index) => {
          return (
            <div className="pics" key={item.index}>
              <img
                src={item.imgSrc}
                onClick={() => getImg(item.imgSrc)}
                width="100%"
                height="100%"
              />
            </div>
          );
        })}
      </div>

      <Footer />
      </Container>
    // </>
  );
}

export default Gallery;
